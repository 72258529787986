.nav {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}
.nav .menu,
.nav .menu:hover {
  cursor: pointer;
}
.nav .menu .ant-menu {
  line-height: 68px;
}
.nav .menu .ant-menu .ant-menu-item {
  border: none;
  position: relative;
  font-size: 16px;
}
.nav .menu .ant-menu .ant-menu-item .text:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 0;
  height: 2px;
  margin-top: 2px;
  background: #c7000b;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.nav .menu .ant-menu .ant-menu-item:hover .text:before,
.nav .menu .ant-menu .ant-menu-item.ant-menu-item-selected .text:before {
  left: 0;
  width: 100%;
}

.nav .menu .ant-menu .ant-menu-item:hover,
.nav .menu .ant-menu .ant-menu-item.ant-menu-item-selected,
.nav .menu .ant-menu-item-selected a,
.nav .menu .ant-menu-item a:hover,
.nav .menu .ant-menu-item-selected a:hover {
  color: #c7000b;
}

.nav .menu .ant-menu-horizontal {
  border-bottom: none;
}
.nav .logo {
  position: absolute;
  width: 180px;
  height: 45px;
  top: calc(50% - 22px);
  left: 5%;
  background-image: url("../../assets/logo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
