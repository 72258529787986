.contact {
  margin-top: 20px;
}
.contact .list {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin: 10px 0 30px;
}
.contact .list .item {
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 50px;
  /* border-top: 2px solid #e6e6e6; */
}
.contact .list .item p {
  margin: 0.5em 0;
}
.contact .list .item em {
  font-weight: bold;
}
.contact .list .item .inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.contact .list .item-img {
  margin: 0 100px;
  flex: 1.5;
}
.contact .list .item-img .inner {
  flex: 1;
  align-items: center;
}
.contact .list .item-img img {
  max-width: 160px;
}
.contact .map {
  width: calc(90% + 30px);
  margin: 0 auto;
}
