.card {
  width: 100%;
  height: 100px;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
}
.card a {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.card a:hover {
  background-color: #f0f0f0;
}
.card .avatar {
  position: absolute;
  width: 150px;
  height: 100px;
  top: 0;
  left: 0;
}
.card .avatar img {
  box-sizing: border-box;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
}
.card .detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 0 0 0 170px;
  line-height: 1.5;
  color: #333;
}
.card .detail .name {
  font-weight: bold;
}
