.carousel img {
  width: 100%;
}
.carousel {
  /* width: 90%; */
  margin: 0 auto;
}
.ant-carousel .slick-arrow {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.ant-carousel:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.ant-carousel .slick-arrow:hover {
  background-color: rgba(255, 255, 255, 0.35);
}

.ant-carousel .slick-arrow::before {
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
.ant-carousel .slick-prev {
  left: 20px;
}
.ant-carousel .slick-next {
  right: 20px;
}
.ant-carousel .slick-prev::before {
  border-right: 15px solid rgba(255, 255, 255, 0.65);
}
.ant-carousel .slick-next::before {
  border-left: 15px solid rgba(255, 255, 255, 0.65);
  margin-left: 15px;
}
