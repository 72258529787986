.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 0;
}

.title .text {
  color: #333;
  font-size: 20px;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
}

.title .text:after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  left: 50%;
  margin-left: 0;
  transform: translateX(-50%);
  bottom: 0;
  min-width: 35px;
}
