.top {
  height: 30px;
  background-color: #111111;
  position: relative;
}
.top .tel {
  position: absolute;
  color: #fff;
  line-height: 30px;
  right: 20px;
  font-size: 14px;
}
