.pic {
  position: relative;
  cursor: pointer;
}
.pic .img-box {
  position: relative;
  width: 100%;
  height: 100%;
  background: #999;
  overflow: hidden;
  border-radius: 10px;
}
.pic .img-box img {
  transition: all 0.5s;
  width: 100%;
}
.pic:hover .img-box img {
  transform: scale(1.1);
}

.pic.slide .img-box .mask {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 70%);
  display: block;
  z-index: 2;
}
.pic.slide .text-box {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  color: #fff;
  text-align: left;
  transition: all 0.5s;
  z-index: 4;
  top: calc(100% - 60px);
  padding: 15px;
  font-size: 14px;
}
.pic.slide .text-box .tit {
  font-size: 20px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.pic.slide .text-box .text {
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  line-height: 1.6;
}
.pic.slide:hover .img-box .mask {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 70%);
}
.pic.slide:hover .text-box {
  top: calc(100% - 100px);
}
.pic.slide:hover .text-box .text {
  opacity: 1;
  visibility: visible;
}
