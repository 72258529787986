.info {
  width: 90%;
  padding: 15px 20px;
}
.info p {
  font-size: 15px;
  text-indent: 2em;
  text-align: justify;
  line-height: 2;
}
