.customer {
  width: 90%;
  margin: 20px auto 0;
  display: flex;
}

.customer .menu {
  max-width: 20%;
  flex: 1;
}
.customer .content {
  margin: 0 10px;
  flex: 1;
}
.customer .menu .ant-menu-item-selected,
.customer .ant-menu-item:hover,
.customer .ant-menu-item-active,
.customer .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.customer .ant-menu-submenu-active,
.customer .ant-menu-submenu-title:hover {
  color: #c7000b;
}

.customer .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #eee;
}

.customer .content ul {
  display: none;
}
.customer .content ul.show {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.customer .content ul.show li {
  margin-right: 20px;
  margin-left: 20px;
  flex: 0 0 45%;
}
